// Generated by Framer (90417e1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./rlJPORNux-0.js";

const serializationHash = "framer-QLIFg"

const variantClassNames = {XKV4o_YWh: "framer-v-925nr8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, yMbRoxWQ3: click ?? props.yMbRoxWQ3} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yMbRoxWQ3, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "XKV4o_YWh", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapneuk7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (yMbRoxWQ3) {const res = await yMbRoxWQ3(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-925nr8", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"XKV4o_YWh"} onTap={onTapneuk7} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1u94fwd"} data-framer-name={"Graphic"} fill={"var(--token-3c507e44-82c6-45fa-bd4f-fdaa185b89ad, rgb(255, 255, 255)) /* {\"name\":\"20240820 / Fg / Main [inverse]\"} */"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"SnO4jz1em"} style={{opacity: 0.8}} svg={"<svg fill=\"none\" viewBox=\"0 0 24 24\"><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10m3.5-10L10 8.25v7.5z\" clip-rule=\"evenodd\"/></svg>"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QLIFg.framer-1y7e02a, .framer-QLIFg .framer-1y7e02a { display: block; }", ".framer-QLIFg.framer-925nr8 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-QLIFg .framer-1u94fwd { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 112px); position: relative; width: 112px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QLIFg.framer-925nr8 { gap: 0px; } .framer-QLIFg.framer-925nr8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QLIFg.framer-925nr8 > :first-child { margin-left: 0px; } .framer-QLIFg.framer-925nr8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 112
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"yMbRoxWQ3":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrlJPORNux: React.ComponentType<Props> = withCSS(Component, css, "framer-QLIFg") as typeof Component;
export default FramerrlJPORNux;

FramerrlJPORNux.displayName = "VideoCursor";

FramerrlJPORNux.defaultProps = {height: 112, width: 112};

addPropertyControls(FramerrlJPORNux, {yMbRoxWQ3: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerrlJPORNux, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})